.Login {
  height: 100vh;
  min-height: 100vh;
  min-width: 100%;
  width: 100%;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}
